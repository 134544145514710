import { Menu } from "@mui/icons-material";
import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import Logo from "../../img/logo.png";
import "./navbar.scss";

function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuClicked, setIsMenuClicked] = useState(false);
  window.onscroll = () => {
    setIsScrolled(window.pageYOffset === 0 ? false : true);
    return () => (window.onscroll = null);
  };

  return (
    <>
      <div className="navbar">
        <div className="navleft">
          <img src={Logo} alt="logo" />
        </div>
        <div className="navRight">
          <Link smooth to="#home" className="link">
            HOME
          </Link>
          <Link smooth to="#about" className="link">
            ABOUT US
          </Link>
          <Link smooth to="#services" className="link">
            OUR SERVICES
          </Link>
          <Link smooth to="#contact" className="link">
            CONTACT US
          </Link>
        </div>
        <div
          className="menuBtn"
          onClick={() => {
            setIsMenuClicked(!isMenuClicked);
          }}
        >
          <Menu />
        </div>
        <div className={isMenuClicked ? "mobileMenu" : "none"}>
          <Link smooth to="#home" className="link">
            HOME
          </Link>
          <Link smooth to="#about" className="link">
            ABOUT US
          </Link>
          <Link smooth to="#services" className="link">
            OUR SERVICES
          </Link>
          <Link smooth to="#contact" className="link">
            CONTACT US
          </Link>
        </div>
      </div>

      <Link smooth to="#home" className="link ">
        <div className={isScrolled ? "scrollUpArrow" : "none"}>
          <img
            className="upArrowImg"
            src="https://www.svgrepo.com/show/93813/up-arrow.svg"
          />
        </div>
      </Link>
    </>
  );
}

export default Navbar;
