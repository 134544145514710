import React from "react";
import "./footer.scss";
function Footer() {
  return (
    <div className="footer">
      <p>Business Promoz | All rights reserved.</p>
    </div>
  );
}

export default Footer;
